import { CHAIN_ID } from "@/constants/environment"
import { formatUnits, getAddress, parseUnits, zeroAddress } from "viem"
import { Address } from "./common.types"
import { getRareToken } from "@/contracts/rare-token"

export type Currency = {
  symbol: string
  address: `0x${string}`
  decimals: number
}

///////////////////////////////////////////////////////////////////////////////
// getRareCurrency
///////////////////////////////////////////////////////////////////////////////
export function getRareCurrency(): Currency {
  return {
    symbol: "RARE",
    address: getRareToken(CHAIN_ID),
    decimals: 18,
  }
}

///////////////////////////////////////////////////////////////////////////////
// getETHCurrency
///////////////////////////////////////////////////////////////////////////////
export function getEthCurrency(): Currency {
  return {
    symbol: "ETH",
    address: zeroAddress,
    decimals: 18,
  }
}

///////////////////////////////////////////////////////////////////////////////
// getUSDCCurrency
///////////////////////////////////////////////////////////////////////////////

export function getUSDCCurrency(): Currency {
  if (CHAIN_ID === 1) {
    return {
      symbol: "USDC",
      address: Address.parse("0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48"),
      decimals: 6,
    }
  }
  if (CHAIN_ID === 11155111) {
    return {
      symbol: "USDC",
      address: getAddress("0x1c7D4B196Cb0C7B01d743Fbc6116a902379C7238"),
      decimals: 6,
    }
  }
  throw new Error("Unsupported chain")
}

///////////////////////////////////////////////////////////////////////////////
// parseEther
///////////////////////////////////////////////////////////////////////////////

export const tryParseEther = (
  value: string,
  decimals: number
): bigint | null => {
  try {
    return parseUnits(value, decimals)
  } catch {
    return null
  }
}

///////////////////////////////////////////////////////////////////////////////
// parseCurrency
///////////////////////////////////////////////////////////////////////////////

export const parseCurrency = (
  amount: string,
  currency: Currency
): bigint | null => {
  const value = parseUnits(amount, currency.decimals)
  if (value === null) {
    return null
  }
  return value
}

///////////////////////////////////////////////////////////////////////////////
// formatCurrency
///////////////////////////////////////////////////////////////////////////////

export const formatCurrency = (value: bigint, currency: Currency): string => {
  return formatUnits(value, currency.decimals)
}

///////////////////////////////////////////////////////////////////////////////
// currencyTypeByAddress
///////////////////////////////////////////////////////////////////////////////
export const currencyTypeByAddress = (address: string): string | undefined => {
  return [getEthCurrency(), getRareCurrency(), getUSDCCurrency()].find(
    (v) => v.address.toLowerCase() === address.toLowerCase()
  )?.symbol
}

///////////////////////////////////////////////////////////////////////////////
// currencyDecimalsByAddress
///////////////////////////////////////////////////////////////////////////////
export const currencyDecimalsByAddress = (
  address: string
): number | undefined => {
  return [getEthCurrency(), getRareCurrency(), getUSDCCurrency()].find(
    (v) => v.address.toLowerCase() === address.toLowerCase()
  )?.decimals
}

///////////////////////////////////////////////////////////////////////////////
// getAllCurrencies
///////////////////////////////////////////////////////////////////////////////
export function getAllCurrencies(): Currency[] {
  const rareCurrency = getRareCurrency()
  const ethCurrency = getEthCurrency()
  const usdcCurrency = getUSDCCurrency()

  return [ethCurrency, rareCurrency, usdcCurrency]
}

///////////////////////////////////////////////////////////////////////////////
// getCurrencyByAddress
///////////////////////////////////////////////////////////////////////////////
export function getCurrencyByAddress(
  address: string | `0x${string}`
): Currency {
  return getAllCurrencies().find(
    (currency) => currency?.address?.toLowerCase() === address?.toLowerCase()
  )!
}

///////////////////////////////////////////////////////////////////////////////
// getCurrencyBySymbol
///////////////////////////////////////////////////////////////////////////////

export function getCurrencyBySymbol(symbol: string): Currency {
  return getAllCurrencies().find(
    (currency) => currency.symbol.toLowerCase() === symbol.toLowerCase()
  )!
}

///////////////////////////////////////////////////////////////////////////////
// formatUnitsToNumber
///////////////////////////////////////////////////////////////////////////////

export function formatUnitsToNumber(
  value: number | bigint,
  decimals: number
): number {
  return Number(formatUnits(BigInt(value), decimals))
}
