// import BigNumber from "bignumber.js"
import { formatEther, formatUnits } from "viem"
import { getCurrencyBySymbol } from "./currency"
import { RouterOutput } from "@/app/api/trpc/[trpc]/infer"

export const formatNumber = (number: number): string => {
  if (number >= 1000000) {
    return number % 1000000 === 0
      ? `${number / 1000000}M`
      : `${(number / 1000000).toFixed(1)}M`
  } else if (number >= 1000) {
    return number % 1000 === 0
      ? `${number / 1000}K`
      : `${(number / 1000).toFixed(1)}K`
  } else if (number < 1) {
    return number.toFixed(2)
  } else {
    return number.toFixed(1)
  }
}

/**
 * Expected eth format is a string '3 ether'
 */
export const ethToDollars = (
  eth: string | number = 0,
  dollarPrice: number
): string => {
  const price = dollarPrice * (typeof eth === "string" ? parseFloat(eth) : eth)
  return formatNumber(price)
}

export const rareToDollarsNumber = (
  rare: number,
  dollarPrice: number
): number => {
  return dollarPrice * (typeof rare === "string" ? parseFloat(rare) : rare)
}

export const formatWei = (wei: number, decimals: number): string => {
  const eth = formatUnits(BigInt(wei), decimals)
  const formatted = formatNumber(Number(eth))
  return formatted
}

export function ethToWei(eth: number) {
  // 1 Ether (ETH) is equal to 10^18 Wei
  const weiInEth = 10 ** 18

  // Convert ETH to Wei
  const wei = eth * weiInEth

  return wei
}

export const calculate3PercentIncrease = (amountInWei: bigint) => {
  const increaseInWei = (amountInWei * BigInt(3)) / BigInt(100)

  const newAmountInWei = amountInWei + increaseInWei

  return newAmountInWei
}

// export const getTxValue = (weiPrice: number) => {
//   return BigInt(
//     new BigNumber(weiPrice)
//       .times(1.03)
//       .decimalPlaces(0, BigNumber.ROUND_FLOOR)
//       .toString() as unknown as bigint
//   )
// }

export const getTxValue = (weiPrice: bigint) => {
  const price = (BigInt(weiPrice) * BigInt(103)) / BigInt(100)
  return price
}

export const getTxValueForBigInt = (weiPrice: bigint) => {
  const increaseInWei = (weiPrice * BigInt(3)) / BigInt(100)
  const newAmountInWei = weiPrice + increaseInWei
  return newAmountInWei
}

const rareAndUsd = (
  amount: bigint,
  usd: number,
  historicalUsd?: number,
  decimals?: number,
  withFee?: boolean
) => {
  const currency = withFee
    ? Number(formatEther(calculate3PercentIncrease(amount))).toFixed(
        decimals || 2
      )
    : Number(formatEther(BigInt(amount))).toFixed(decimals || 2)
  const dollars = historicalUsd
    ? formatNumber(historicalUsd)
    : ethToDollars(currency, usd)
  return { currency, dollars }
}

export const RareUsdCurrency = (
  amount: bigint,
  usd: number,
  historicalUsd?: number,
  decimals?: number,
  withFee?: boolean
): string => {
  const { currency, dollars } = rareAndUsd(
    amount,
    usd,
    historicalUsd,
    decimals,
    withFee
  )
  return currency + " RARE" + " ($" + dollars + ")"
}

export const RareUsdCurrencyObject = (
  amount: bigint,
  usd: number,
  historicalUsd?: number,
  decimals?: number,
  withFee?: boolean
): { currency: string; dollars: string; dollarNumber: number } => {
  const { currency, dollars } = rareAndUsd(
    amount,
    usd,
    historicalUsd,
    decimals,
    withFee
  )

  return {
    currency: currency + " RARE",
    dollars: "$" + dollars,
    dollarNumber: Number(ethToDollars(currency, usd)),
  }
}

export const EthUsdCurrency = (
  amount: bigint,
  usd: number,
  historicalUsd?: number,
  decimals?: number,
  withFee?: boolean
): string => {
  const currency = withFee
    ? Number(formatEther(calculate3PercentIncrease(amount))).toFixed(
        decimals || 3
      )
    : Number(formatEther(amount)).toFixed(decimals || 3)
  const dollars = historicalUsd
    ? formatNumber(historicalUsd)
    : ethToDollars(currency, usd)

  return currency + "Ξ" + " ($" + dollars + ")"
}

export const EthUsdCurrencyObject = (
  amount: bigint,
  usd: number,
  historicalUsd?: number,
  decimals?: number,
  withFee?: boolean
): { currency: string; dollars: string; dollarNumber: number } => {
  const currency = withFee
    ? Number(formatEther(calculate3PercentIncrease(amount))).toFixed(
        decimals || 3
      )
    : Number(formatEther(BigInt(amount))).toFixed(decimals || 3)
  const dollars = historicalUsd
    ? formatNumber(historicalUsd)
    : ethToDollars(currency, usd)

  return {
    currency: currency + "Ξ",
    dollars: "$" + dollars,
    dollarNumber: Number(ethToDollars(currency, usd)),
  }
}

export const UsdcCurrencyUsd = (
  amount: bigint,
  decimals?: number,
  withFee?: boolean
): string => {
  const usdcDecimals = getCurrencyBySymbol("USDC").decimals
  const currency = withFee
    ? Number(
        formatUnits(calculate3PercentIncrease(amount), usdcDecimals)
      ).toFixed(decimals || 3)
    : Number(formatUnits(BigInt(amount), usdcDecimals)).toFixed(decimals || 3)
  const dollars = formatNumber(Number(currency))

  return currency + " USDC" + " ($" + dollars + ")"
}

export const UsdcCurrencyUsdObject = (
  amount: bigint,
  decimals?: number,
  withFee?: boolean
): { currency: string; dollars: string; dollarNumber: number } => {
  const usdcDecimals = getCurrencyBySymbol("USDC").decimals
  const currency = withFee
    ? Number(
        formatUnits(calculate3PercentIncrease(amount), usdcDecimals)
      ).toFixed(decimals || 3)
    : Number(formatUnits(BigInt(amount), usdcDecimals)).toFixed(decimals || 3)
  const dollars = formatNumber(Number(currency))

  return {
    currency: currency + " USDC",
    dollars: "$" + dollars,
    dollarNumber: Number(currency),
  }
}

export const getUsdCurrencyObject = ({
  amount,
  usd = 0,
  historicalUsd,
  decimals,
  withFee,
  currencyType,
}: {
  amount: bigint
  usd?: number
  historicalUsd?: number
  decimals?: number
  withFee?: boolean
  currencyType?: string
}): { currency: string; dollars: string; dollarNumber: number } => {
  if (currencyType === "ETH") {
    return EthUsdCurrencyObject(amount, usd, historicalUsd, decimals, withFee)
  } else if (currencyType === "RARE") {
    return RareUsdCurrencyObject(amount, usd, historicalUsd, decimals, withFee)
  } else if (currencyType === "USDC") {
    return UsdcCurrencyUsdObject(amount, decimals, withFee)
  } else {
    return { currency: "", dollars: "", dollarNumber: 0 }
  }
}

export const getHighestOffer = ({
  ethPrice,
  rarePrice,
  offers,
}: {
  offers: RouterOutput["adp"]["hero"]["offers"]
  rarePrice: number
  ethPrice: number
}): RouterOutput["adp"]["hero"]["offers"][0] => {
  if (offers.length === 1) return offers[0]
  const highestOfferIndex: { index: number; dollars: number } = offers.reduce(
    (acc, offer, index) => {
      if (!offer) {
        return acc
      }
      const isUsdc = offer.currency?.type === "USDC"
      const isRare = offer.currency?.type === "RARE"
      const usdAmount = isUsdc
        ? Number(formatUnits(BigInt(offer.amount), 6))
        : rareToDollarsNumber(offer.amount, isRare ? rarePrice : ethPrice)
      if (usdAmount > acc.dollars) {
        return { index, dollars: usdAmount }
      }
      return acc
    },
    {
      index: 0,
      dollars: 0,
    }
  )
  return offers[highestOfferIndex.index]
}
