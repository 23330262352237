import { ProfileOrderBy, User } from "@/graphql/generated/apollo/graphql"
import { create } from "zustand"

export type FilterValue = {
  [key: string]: string[] | Record<string, string[]>
}

type Profile = {
  profile: User | undefined
  setProfile: (profile: any) => void

  mastheadArtwork: any
  setMastheadArtwork: (mastheadArtwork: any) => void

  orderBy: ProfileOrderBy
  setOrderBy: (orderBy: ProfileOrderBy) => void

  isFiltersOpen: boolean
  toggleFilters: () => void

  viewMode: "grid" | "masonry"
  setViewMode: (viewMode: "grid" | "masonry") => void

  filters: Record<string, FilterValue>
  setFilters: (filters: Record<string, FilterValue>) => void
  countActiveFilters: () => number
  clearFilters: () => void

  isHeapLoaded: boolean
  setIsHeapLoaded: (status: boolean) => void

  rareBalance: bigint
  setRareBalance: (rareBalance: bigint) => void

  usdcBalance: number
  setUsdcBalance: (usdcBalance: number) => void
}

export const useProfileStore = create<Profile>((set, get) => ({
  profile: undefined,
  setProfile: (profile: any) => set({ profile }),

  mastheadArtwork: undefined,
  setMastheadArtwork: (mastheadArtwork: any) => set({ mastheadArtwork }),

  orderBy: ProfileOrderBy.DateMintedDesc,
  setOrderBy: (orderBy: ProfileOrderBy) => set({ orderBy }),

  isFiltersOpen: false,
  toggleFilters: () =>
    set((state) => ({ isFiltersOpen: !state.isFiltersOpen })),

  viewMode: "grid",
  setViewMode: (viewMode) => set({ viewMode }),

  filters: {},
  setFilters: (filters) => set({ filters }),
  countActiveFilters: () => {
    let count = 0
    const filters = get().filters

    Object.values(filters).forEach((group) => {
      if (typeof group === "object" && group !== null) {
        Object.entries(group).forEach(([, value]) => {
          if (Array.isArray(value)) {
            // If the array is empty, count the key as 1. If it has elements, count only the elements.
            count += value.length === 0 ? 1 : value.length
          }
        })
      }
    })

    return count
  },
  clearFilters: () => set({ filters: {} }),

  isHeapLoaded: false,
  setIsHeapLoaded: (isHeapLoaded) => set({ isHeapLoaded }),

  rareBalance: 0n,
  setRareBalance: (rareBalance) => set({ rareBalance }),

  usdcBalance: 0,
  setUsdcBalance: (usdcBalance) => set({ usdcBalance }),
}))
