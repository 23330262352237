"use client"

import * as Tooltip from "@radix-ui/react-tooltip"
import { useCopyToClipboard } from "react-use"
import { useState, useEffect } from "react"
import { cn } from "@/utils/cn"

interface EthAddressProps {
  address: string | any
  className?: string
  toolTipLocation?: "top" | "right" | "bottom" | "left"
}

export const EthformatAddress = (
  address: string,
  startLength = 5,
  endLength = 5
): string => {
  return `${address?.slice(0, startLength)}...${address?.slice(-endLength)}`
}

export const EthAddress = ({
  address,
  className,
  toolTipLocation,
}: EthAddressProps): JSX.Element => {
  const [state, copyToClipboard] = useCopyToClipboard()
  const [tooltipText, setTooltipText] = useState("Copy")
  const [tooltipOpen, setTooltipOpen] = useState(false)

  const handleClick = (): void => {
    copyToClipboard(address)
    setTooltipOpen(true)
  }

  useEffect(() => {
    if (state.error) {
      setTooltipText("Failed to copy")
    } else if (state.value) {
      setTooltipText("Copied")
      setTimeout(() => {
        setTooltipText("Copy")
        setTooltipOpen(false)
      }, 1000)
    }
  }, [state])

  return (
    <>
      <Tooltip.Provider>
        <Tooltip.Root
          delayDuration={0}
          open={tooltipOpen}
          onOpenChange={setTooltipOpen}
        >
          <Tooltip.Trigger asChild onClick={handleClick}>
            <button
              className={cn("mt-[1px] text-typography-grey-2", className)}
            >
              {EthformatAddress(address)}
            </button>
          </Tooltip.Trigger>
          <Tooltip.Portal>
            <Tooltip.Content
              side={toolTipLocation ?? "right"}
              className={`rounded-md border border-sr-border-primary px-3 py-1 text-xs`}
              sideOffset={6}
            >
              <button onClick={handleClick}>{tooltipText}</button>
            </Tooltip.Content>
          </Tooltip.Portal>
        </Tooltip.Root>
      </Tooltip.Provider>
    </>
  )
}
